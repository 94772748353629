import React, { useState } from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    SelectInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    NumberField,
    minValue,
    useDataProvider,
    useRefresh,
    useNotify,
    Button,
    FunctionField,
    useRecordContext,
    ArrayField,
    SimpleForm,
    required,
    NumberInput,
    SelectArrayInput,
    SimpleShowLayout,
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { SystemsTabInlineEdit } from './ra_systems';

export const ConfigurationsTab = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [modalRecord, setModalRecord] = useState(null);
    const [cindex, setIndex] = useState(null);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const sanitizeData = (data) => {
        if (Array.isArray(data)) {
            return data.map(sanitizeData);
        } else if (data && typeof data === 'object') {
            const sanitized = {};
            Object.keys(data).forEach((key) => {
                const value = data[key];
                if (value !== undefined) {
                    sanitized[key] = sanitizeData(value);
                } else {
                    // Replace undefined with null or a default value if appropriate
                    sanitized[key] = null; // Or provide a default value
                }
            });
            return sanitized;
        } else {
            return data;
        }
    };
    const handleAdd = () => {
        setModalRecord(null);
        setIndex(null);
        setOpen(true);
    };

    const handleEdit = (config, index) => {
        setModalRecord(config);
        setIndex(record.configurations.indexOf(config));
        setOpen(true);
    };

    const handleSave = async (configData) => {
        try {
            console.log("RECORD", record);
            let updatedConfigurations;
            if (cindex !== null) {
                // Update existing configuration
                updatedConfigurations = record.configurations.map((config, index) =>
                    index === cindex ? configData : config
                );
            } else {
                // Add new configuration
                updatedConfigurations = [...(record.configurations || []), configData];
            }
            const sanitized = sanitizeData({ ...record, configurations: updatedConfigurations });
            await dataProvider.update('projects', {
                id: record.id,
                data: sanitized,
                previousData: record,
            });
            setOpen(false);
            refresh();
            notify('Configuration saved', { type: 'info' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    return (
        <>
            <Button onClick={handleAdd} variant="text" color="primary">
                Add Configuration
            </Button>

            <ArrayField source="configurations">
                <Datagrid >
                    <TextField source="configName" label="Configuration Name" />
                    <NumberField source="sumup.sumup_mass" label="Total Mass" defaultValue={0}
                        validate={[required(), minValue(0)]} />
                    <NumberField source="sumup.sumup_price" label="Total Price" defaultValue={0}
                        validate={[required(), minValue(0)]} />
                    <FunctionField
                        label="Actions"
                        render={(record, index) => (
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleEdit(record, index);
                                }}
                            >
                                Edit
                            </Button>
                        )}
                    />
                </Datagrid>
            </ArrayField>
            {open && (
                <ConfigurationModal
                    open={open}
                    onClose={() => setOpen(false)}
                    configuration={
                        modalRecord !== null
                            ? modalRecord
                            : null
                    }
                    cindex={cindex}
                    onSave={handleSave}
                    projectSystems={record.systems}
                />
            )}
        </>
    );
};
export const ConfigurationModal = ({ open, onClose, configuration, onSave, projectSystems, cindex }) => (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{configuration ? 'Edit Configuration ' + cindex : 'Add Configuration'}</DialogTitle>
        <DialogContent>
            <ConfigurationForm
                configuration={configuration}
                onSave={onSave}
                onCancel={onClose}
                projectSystems={projectSystems}
            />
            <SystemsTabInlineEdit/>
        </DialogContent>
    </Dialog>
);
export const ConfigurationForm = ({ configuration, onSave, onCancel, projectSystems }) => {
    const handleSubmit = (values) => {
        onSave(values);
    };

    return (
        <SimpleForm
            record={configuration}
            onSubmit={handleSubmit}
            toolbar={null} // Hide the default toolbar
        >
            <TextInput
                source="configName"
                label="Configuration Name"
                validate={required()}
                fullWidth
            />
            <TextInput
                source="description"
                label="Description"
                multiline
                fullWidth
            />
            {/* Sum-Up Fields */}
            <NumberInput
                source="sumup.sumup_mass"
                label="Total Mass"
                defaultValue={0}
                validate={[required(), minValue(0)]}
                disabled
            />
            <NumberInput
                source="sumup.sumup_price"
                label="Total Price"
                defaultValue={0}
                validate={[required(), minValue(0)]}
                disabled
            />

            {/* Systems Selection */}
            <SelectArrayInput
                label="Systems"
                source="systemIndexes"
                choices={projectSystems.map((system, index) => ({
                    id: index,
                    name: system.systemName || `System ${index + 1}`,
                }))}
                optionText="name"
                optionValue="id"
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="submit" color="primary">
                    Save
                </Button>
            </div>
        </SimpleForm>
    );
};
export const ConfigurationShow = ({ configuration, allSystems }) => {
    if (!configuration) return null;

    // Map systemIndexes to actual systems
    const systems = (configuration.systemIndexes || []).map(
        (index) => allSystems[index]
    );

    return (
        <SimpleShowLayout record={configuration}>
            <TextField source="configName" label="Configuration Name" />
            <TextField source="description" label="Description" />
            {/* Sum-Up Fields */}
            <NumberField source="sumup.sumup_mass" label="Total Mass" defaultValue={0}
                validate={[required(), minValue(0)]} />
            <NumberField source="sumup.sumup_price" label="Total Price" defaultValue={0}
                validate={[required(), minValue(0)]} />
            {/* Systems */}
            {systems && systems.length > 0 && (
                <ArrayField source="systems" record={{ systems }}>
                    <Datagrid>
                        <TextField source="systemName" label="System Name" />
                        {/* Other system fields */}
                    </Datagrid>
                </ArrayField>
            )}
        </SimpleShowLayout>
    );
};
