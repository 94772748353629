import { motion, AnimatePresence } from "framer-motion"; // Import motion from framer-motion
import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import useResponsive from "./useResponsive";
import DecalTool from "./3dDecalComponent";
import { Raycaster } from "three";
import {
	faChevronDown,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
	VRButton,
	XR,
	Controllers,
	Hands,
	useXR,
	useXREvent,
} from "@react-three/xr";
import {
	faExpand,
	faCompress,
	faPaintBrush,
	faCamera,
	faQuestion,
	faLayerGroup,
	faX,
	faY,
	faZ,
	faEye,
	faObjectGroup,
	faEyeSlash,
	faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import config from "../assets/config";
import HoverButton from "./HoverButton";
import * as TWEEN from "@tweenjs/tween.js"; // Use the full TWEEN namespace
import Stack from "./Stack";
import { Vector3, Plane, Box3, Mesh } from "three";
import { HexColorPicker, HexColorInput} from "react-colorful"; // If you're using react-colorful
const buttonStyle = {
	fontSize: "20px",
	padding: "10px 10px",
	margin: "5px",
};
const mainButtonStyle = {
	...buttonStyle,
	fontSize: "30px",
	padding: "10px 15px",
	margin: "5px",
	// backgroundColor: "rgba(255,255,255,1)",
	// color: config.colors.secondary,
};
const containerStyle = {
	position: "absolute",
	backgroundColor: "rgba(255,255,255,0.1)",
	padding: "5px",
	margin: "10px",
	backdropFilter: "blur(100px)",
	borderRadius: "30px",
	display: "flex",
	flexDirection: "row",
	alignItems: "flex-start",
	index: 1000,
	boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
};
function FullScreenButton({ toggleFullScreen }) {
	return (
		<HoverButton
			icon={faExpand}
			config={config}
			onClick={toggleFullScreen}
			style={{
				position: "absolute",
				top: "100%",
				left: "100%",
				fontSize: "30px",
				transform: "translate(-100%, -100%)",
				padding: "10px 15px",
				margin: "-10px",
			}}
		/>
	);
}
function LoadingIndicator() {
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: 1000,
			}}
		>
			<div className="spinner"></div>
			<style>
				{`
                .spinner {
                  border: 5px solid #f3f3f3;
                  border-top: 5px solid #3498db;
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  animation: spin 2s linear infinite;
                }
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
			</style>
		</div>
	);
}
function OptionsButton({
	toggleFullScreen,
	toggleMaterialOptions,
	toggleLayerOptions,
	toggleClippingOptions,
	switchCamera,
	cameras,
	admin = false,
	showResize = false,
	hasColorOptions = false,
	hasLayers = false,
}) {
	return (
		<motion.div
			style={{
				position: "absolute",
				backgroundColor: "rgba(255,255,255,0.1)",
				padding: "5px",
				margin: "10px",
				transform: `translate(0,50%)`,
				bottom: "50%",
				right: "0",
				backdropFilter: "blur(100px)",
				borderRadius: "40px",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				index: 1000,
				boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
			}}
		>
			{showResize && (
				<HoverButton
					config={config}
					icon={faCompress}
					onClick={toggleFullScreen}
					style={{
						...mainButtonStyle,
						backgroundColor: config.colors.secondary,
						color: "white",
					}}
				/>
			)}
			<HoverButton
				config={config}
				icon={faCamera}
				onClick={switchCamera} // Toggles material options
				disabled={cameras.length == 0 ? true : false}
				style={mainButtonStyle}
				BackgroundColor={"transparent"}
			/>
			{hasColorOptions && (
				<HoverButton
					config={config}
					icon={faPaintBrush}
					onClick={toggleMaterialOptions} // Toggles material options
					style={mainButtonStyle}
					BackgroundColor={"transparent"}
				/>
			)}
			{admin && (
				<HoverButton
					config={config}
					icon={faObjectGroup}
					onClick={toggleClippingOptions} // Toggles material options
					style={mainButtonStyle}
					BackgroundColor={"transparent"}
				/>
			)}
			{admin && hasLayers && (
				<HoverButton
					config={config}
					icon={faLayerGroup}
					onClick={toggleLayerOptions} // Toggles material options
					style={mainButtonStyle}
					BackgroundColor={"transparent"}
				/>
			)}
			<HoverButton
				config={config}
				icon={faQuestion}
				onClick={() => {
					window.alert(
						"Right Mouse Button to Pan\n Left Mouse to Orbit\n Mouse Wheel to Zoom!"
					);
				}} // Toggles material options
				style={mainButtonStyle}
				BackgroundColor={"transparent"}
			/>
		</motion.div>
	);
}
function ClippingTab({
	applyClip,
	reverseClip,
	clipValues,
	setClipValues,
	togglePlane,
	enabledAxes,
	objectBounds,
}) {
	const handleSliderChange = (axis, value) => {
		setClipValues((prev) => ({ ...prev, [axis]: value }));
		applyClip(axis, value);
	};

	// If objectBounds is not available, render a loading message or fallback
	if (!objectBounds) {
		return (
			<motion.div
				initial={{ opacity: 0, y: -50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}
				style={{
					position: "absolute",
					backgroundColor: "rgba(255,255,255,0.1)",
					padding: "5px",
					margin: "10px",
					top: "0",
					right: "0",
					backdropFilter: "blur(100px)",
					borderRadius: "30px",
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					index: 1000,
					boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
				}}
			>
				<strong style={{ color: "white" }}>Clipping Options:</strong>
				<p style={{ color: "white" }}>Loading bounds...</p>
			</motion.div>
		);
	}
	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			transition={{ duration: 0.5 }}
			style={{
				position: "absolute",
				backgroundColor: "rgba(255,255,255,0.1)",
				padding: "5px",
				margin: "10px",
				bottom: "0",
				left: "0",
				backdropFilter: "blur(100px)",
				borderRadius: "30px",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				index: 1000,
				boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
			}}
		>
			<div style={{ display: "flex", flexDirection: "horizontal" }}>
				<HoverButton
					config={config}
					icon={faEyeSlash}
					onClick={() => togglePlane("none")}
					style={{
						...buttonStyle,
					}}
					BackgroundColor={"transparent"}
				/>
				<HoverButton
					config={config}
					icon={faX}
					onClick={() => togglePlane("x")}
					style={{
						...buttonStyle,
					}}
					BackgroundColor={"#ef4444"}
				/>
				<HoverButton
					config={config}
					icon={faY}
					onClick={() => togglePlane("y")}
					style={{
						...buttonStyle,
					}}
					BackgroundColor={"#22c55e"}
				/>
				<HoverButton
					config={config}
					icon={faZ}
					onClick={() => togglePlane("z")}
					style={{
						...buttonStyle,
					}}
					BackgroundColor={"#3b82f6"}
				/>
			</div>
			{/* Toggle Clipping Planes */}
			<strong style={{ color: "white", textAlign: "center", width: "100%" }}>
				Clipping Options
			</strong>
			{/* Sliders for enabled planes */}
			{enabledAxes.x && (
				<>
					<label style={{ color: "white", width: "100%" }}>
						Move X-Axis Clipping
					</label>
					<div
						style={{
							display: "flex",
							flexDirection: "horizontal",
							width: "100%",
						}}
					>
						<input
							id="minmax-range"
							type="range"
							min={objectBounds.min.x}
							max={objectBounds.max.x}
							step="0.01"
							value={clipValues.x}
							onChange={(e) =>
								handleSliderChange("x", parseFloat(e.target.value))
							}
							class="w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-black/25 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[30px] [&::-webkit-slider-thumb]:w-[30px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-red-500"
						/>
						<HoverButton
							config={config}
							icon={faArrowsRotate}
							onClick={() => reverseClip("x")}
							style={{
								...buttonStyle,
							}}
							BackgroundColor={"transparent"}
						/>
					</div>
				</>
			)}
			{enabledAxes.y && (
				<>
					<label style={{ color: "white" }}>Move Y-Axis Clipping</label>
					<div
						style={{
							display: "flex",
							flexDirection: "horizontal",
							width: "100%",
						}}
					>
						<input
							id="minmax-range"
							type="range"
							min={objectBounds.min.z}
							max={objectBounds.max.z}
							step="0.01"
							value={clipValues.y}
							onChange={(e) =>
								handleSliderChange("y", parseFloat(e.target.value))
							}
							class="w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-black/25 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[30px] [&::-webkit-slider-thumb]:w-[30px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-green-500"
						/>
						<HoverButton
							config={config}
							icon={faArrowsRotate}
							onClick={() => reverseClip("z")}
							style={{
								...buttonStyle,
							}}
							BackgroundColor={"transparent"}
						/>
					</div>
				</>
			)}
			{enabledAxes.z && (
				<>
					<label style={{ color: "white" }}>Move Z-Axis Clipping</label>

					<div
						style={{
							display: "flex",
							flexDirection: "horizontal",
							width: "100%",
						}}
					>
						<input
							id="minmax-range"
							type="range"
							min={objectBounds.min.y}
							max={objectBounds.max.y}
							step="0.01"
							value={clipValues.z}
							onChange={(e) =>
								handleSliderChange("z", parseFloat(e.target.value))
							}
							class="w-full appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-black/25 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[30px] [&::-webkit-slider-thumb]:w-[30px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-blue-500"
						/>
						<HoverButton
							config={config}
							icon={faArrowsRotate}
							onClick={() => reverseClip("y")}
							style={{
								...buttonStyle,
							}}
							BackgroundColor={"transparent"}
						/>
					</div>
				</>
			)}
			{/* Reverse Clip Button */}
		</motion.div>
	);
}

function ColorsTab1({ modelOptions, updateMaterial }) {
	const ref = useRef();
	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0, x: "-50%", y: -50 }} // Start off-screen
			animate={{ opacity: 1, y: 0 }} // Animate into the scene
			exit={{ opacity: 0, y: -50 }} // Animate out of the scene
			transition={{ duration: 0.5 }}
			style={{
				...containerStyle,
				top: "0",
				left: "50%",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{modelOptions?.materials.map((mesh, index) => (
					<Stack direction="v" columnsJustification="start" key={index}>
						<strong style={{ color: "white" }}> {mesh.displayName}:</strong>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							{mesh.materials.map((material, i) => (
								<HoverButton
									key={i}
									config={config}
									icon={faPaintBrush}
									onClick={() => updateMaterial(mesh.meshName, material.name)}
									style={{
										...buttonStyle,
										color: isColorCloseToWhite(material.color)
											? "gray"
											: "white",
										margin: "5px",
									}}
									BackgroundColor={material.color}
								/>
							))}
						</div>
					</Stack>
				))}
			</div>
		</motion.div>
	);
}
function ColorsTab2({ modelOptions, updateMaterial }) {
	const [customColor, setCustomColor] = useState("#ffffff");
	const [pickerOpen, setPickerOpen] = useState(null); // Track which material is being edited

	if (!modelOptions?.materials) return null;

	return (
		<motion.div
			initial={{ opacity: 0, x: "-50%", y: -50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.5 }}
			style={{
				position: "absolute",
				top: 0,
				left: "50%",
				transform: "translateX(-50%)",
				backgroundColor: "rgba(255,255,255,0.1)",
				padding: "10px",
				margin: "10px",
				backdropFilter: "blur(100px)",
				borderRadius: "30px",
				display: "flex",
				flexDirection: "column",
				zIndex: 1000,
				boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
			}}
		>
			{modelOptions.materials.map((materialGroup, index) => (
				<div key={index} style={{ marginBottom: "10px" }}>
					<strong style={{ color: "white" }}>
						{materialGroup.displayName}
					</strong>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							gap: "6px",
							marginTop: "6px",
						}}
					>
						{materialGroup.materials.map((material, i) => (
							<HoverButton
								key={i}
								config={config}
								icon={faPaintBrush}
								onClick={() =>
									updateMaterial(
										materialGroup.meshName || materialGroup.materialName,
										material.name
									)
								}
								style={{
									fontSize: "14px",
									padding: "10px",
									color: isColorCloseToWhite(material.color) ? "gray" : "white",
								}}
								BackgroundColor={material.color}
							/>
						))}

						{/* Custom Color Picker Toggle */}
						{materialGroup.customizable && (
							<>
								<HoverButton
									config={config}
									icon={faPaintBrush}
									onClick={() =>
										setPickerOpen(pickerOpen === index ? null : index)
									}
									style={{
										fontSize: "14px",
										padding: "10px",
										color: "white",
										border: "1px dashed white",
										backgroundColor: "transparent",
									}}
								/>
								{/* Color Picker Component */}
								{pickerOpen === index && (
									<div style={{ position: "relative", zIndex: 2000 }}>
										<input
											type="color"
											value={customColor}
											onChange={(e) => {
												const color = e.target.value;
												setCustomColor(color);
												updateMaterial(
													materialGroup.meshName || materialGroup.materialName,
													materialGroup.materials[0].name,
													color
												);
											}}
											style={{
												width: "50px",
												height: "40px",
												border: "none",
												cursor: "pointer",
											}}
										/>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			))}
		</motion.div>
	);
}
function ColorsTab3({ modelOptions, updateMaterial }) {
	const [colorPickers, setColorPickers] = useState({});
	const [customColors, setCustomColors] = useState({}); // Stores last-picked colors per mesh/material

	const togglePicker = (meshName, materialName) => {
		const key = `${meshName}_${materialName}`;
		setColorPickers((prev) => ({
			...prev,
			[key]: !prev[key],
		}));
	};

	const handleColorChange = (meshName, materialName, color) => {
		const key = `${meshName}_${materialName}`;

		// Store selected color for persistence
		setCustomColors((prev) => ({
			...prev,
			[key]: color,
		}));

		// Apply the new color without affecting other material properties
		updateMaterial(meshName, null, {
			color,
			originalName: materialName,
		});
	};

	return (
		<div style={{ ...containerStyle, top: "0", left: "50%", flexDirection: "column" }}>
			{modelOptions?.materials.map((mesh, meshIndex) => (
				<div key={meshIndex} style={{ marginBottom: "10px" }}>
					<strong style={{ color: "white" }}>{mesh.displayName}</strong>
					<div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
						{mesh.materials.map((mat, i) => {
							const key = `${mesh.meshName}_${mat.name}`;
							const pickedColor = customColors[key] || mat.color;
							const isPickerOpen = colorPickers[key];

							return (
								<div key={i} style={{ margin: "5px", textAlign: "center" }}>
									<HoverButton
										config={config}
										icon={faPaintBrush}
										onClick={() => togglePicker(mesh.meshName, mat.name)}
										style={{
											width: "30px",
											height: "30px",
											backgroundColor: pickedColor,
											color: isColorCloseToWhite(pickedColor) ? "gray" : "white",
											borderRadius: "50%",
											border: "2px solid white",
										}}
									/>
									{isPickerOpen && (
										<div style={{ marginTop: "5px", position: "absolute", zIndex: 999 }}>
											<HexColorPicker
												color={pickedColor}
												onChange={(color) =>
													handleColorChange(mesh.meshName, mat.name, color)
												}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
}
function ColorsTab4({ modelOptions, updateMaterial }) {
	const [selectedColor, setSelectedColor] = useState({}); // Which color is selected per material
	const [customColors, setCustomColors] = useState({});   // Custom-picked color per material

	const toggleOrSelectColor = (meshName, materialName, color) => {
		const key = `${meshName}_${materialName}`;

		// If the same color is already selected, toggle picker visibility
		if (selectedColor[meshName] === materialName) {
			setSelectedColor((prev) => ({
				...prev,
				[meshName]: prev[meshName] === key + "_picker" ? materialName : key + "_picker",
			}));
		} else {
			// Set as selected and apply the material
			setSelectedColor((prev) => ({
				...prev,
				[meshName]: materialName,
			}));

			updateMaterial(meshName, materialName); // Apply base color material
		}
	};

	const handleColorChange = (meshName, materialName, color) => {
		const key = `${meshName}_${materialName}`;

		setCustomColors((prev) => ({
			...prev,
			[key]: color,
		}));

		// Only update color field
		updateMaterial(meshName, null, {
			color,
			originalName: materialName,
		});
	};

	return (
		<div style={{ ...containerStyle, top: "0", left: "50%", flexDirection: "column" }}>
			{modelOptions?.materials.map((mesh, meshIndex) => (
				<div key={meshIndex} style={{ marginBottom: "10px" }}>
					<strong style={{ color: "white" }}>{mesh.displayName}</strong>
					<div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
						{mesh.materials.map((mat, i) => {
							const key = `${mesh.meshName}_${mat.name}`;
							const pickedColor = customColors[key] || mat.color;
							const isPickerOpen = selectedColor[mesh.meshName] === key + "_picker";
							const isActive = selectedColor[mesh.meshName]?.includes(mat.name);

							return (
								<div key={i} style={{ margin: "5px", textAlign: "center", position: "relative" }}>
									<HoverButton
										config={config}
										icon={faPaintBrush}
										onClick={() =>
											toggleOrSelectColor(mesh.meshName, mat.name, mat.color)
										}
										style={{
											width: "30px",
											height: "30px",
											backgroundColor: pickedColor,
											color: isColorCloseToWhite(pickedColor) ? "gray" : "white",
											borderRadius: "50%",
											border: isActive ? "3px solid white" : "2px solid gray",
										}}
									/>
									{isPickerOpen && (
										<div style={{ position: "absolute", top: "40px", zIndex: 999 }}>
											<HexColorPicker
												color={pickedColor}
												onChange={(color) =>
													handleColorChange(mesh.meshName, mat.name, color)
												}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
}
function ColorsTab5({ modelOptions, updateMaterial }) {
	const ref = useRef();
	const [showPickerFor, setShowPickerFor] = useState(null); // Track which material is showing picker
	const [customColor, setCustomColor] = useState("#ffffff");

	const handleCustomClick = (meshName) => {
		if (showPickerFor === meshName) {
			setShowPickerFor(null); // Toggle off
		} else {
			setShowPickerFor(meshName);
		}
	};

	const applyCustomColor = (meshName) => {
		updateMaterial(meshName, null, { color: customColor });
	};

	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0, x: "-50%", y: -50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.5 }}
			style={{
				...containerStyle,
				top: "0",
				left: "50%",
				flexDirection: "column",
			}}
		>
			<div style={{ display: "flex", flexDirection: "column" }}>
				{modelOptions?.materials.map((mesh, index) => (
					<Stack direction="v" columnsJustification="start" key={index}>
						<strong style={{ color: "white" }}>{mesh.displayName}:</strong>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							{mesh.materials.map((material, i) => (
								<HoverButton
									key={i}
									config={config}
									icon={faPaintBrush}
									onClick={() => updateMaterial(mesh.meshName, material.name)}
									style={{
										...buttonStyle,
										color: isColorCloseToWhite(material.color)
											? "gray"
											: "white",
										margin: "5px",
									}}
									BackgroundColor={material.color}
								/>
							))}
							{/* Custom color button */}
							<HoverButton
								key="custom"
								config={config}
								icon={faPaintBrush}
								onClick={() => handleCustomClick(mesh.meshName)}
								style={{
									...buttonStyle,
									color: "white",
									margin: "5px",
									border: "2px dashed white",
								}}
								BackgroundColor={customColor}
							/>
						</div>
						{/* Show color picker */}
						{showPickerFor === mesh.meshName && (
							<div style={{ marginTop: "10px", padding: "5px" }}>
								<HexColorPicker
									color={customColor}
									onChange={(color) => setCustomColor(color)}
								/>
								<div style={{ marginTop: "5px", display: "flex", alignItems: "center" }}>
									<HexColorInput
										color={customColor}
										onChange={setCustomColor}
										style={{
											width: "100px",
											padding: "5px",
											marginRight: "10px",
											borderRadius: "5px",
										}}
									/>
									<button
										onClick={() => applyCustomColor(mesh.meshName)}
										style={{
											background: "#fff",
											padding: "5px 10px",
											borderRadius: "5px",
											cursor: "pointer",
										}}
									>
										Apply
									</button>
								</div>
							</div>
						)}
					</Stack>
				))}
			</div>
		</motion.div>
	);
}
function ColorsTab({ modelOptions, updateMaterial }) {
	const ref = useRef();

	// Store picker visibility and color for each mesh
	const [pickerState, setPickerState] = useState({}); // { [meshName]: { show: boolean, color: "#fff" } }

	const togglePicker = (meshName, defaultColor = "#ffffff") => {
		setPickerState((prev) => ({
			...prev,
			[meshName]: {
				show: !prev[meshName]?.show,
				color: prev[meshName]?.color || defaultColor,
			},
		}));
	};

	const handleCustomChange = (meshName, newColor) => {
		setPickerState((prev) => ({
			...prev,
			[meshName]: {
				...prev[meshName],
				color: newColor,
			},
		}));
		updateMaterial(meshName, null, { color: newColor });
	};

	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0, x: "-50%", y: -50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.5 }}
			style={{
				...containerStyle,
                minWidth:"229px",
				top: "0",
				left: "50%",
				flexDirection: "column",
			}}
		>
			<div style={{ display: "flex", flexDirection: "column" }}>
				{modelOptions?.materials.map((mesh, index) => {
					const picker = pickerState[mesh.meshName];
					return (
						<Stack direction="v" columnsJustification="start" key={index}>
							<strong style={{ color: "white" }}>{mesh.displayName}:</strong>
							<div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
								{mesh.materials.map((material, i) => (
									<HoverButton
										key={i}
										config={config}
										icon={faPaintBrush}
										onClick={() =>
											updateMaterial(mesh.meshName, material.name)
										}
										style={{
											...buttonStyle,
											color: isColorCloseToWhite(material.color)
												? "gray"
												: "white",
										}}
										BackgroundColor={material.color}
									/>
								))}
								{/* Custom Color Button */}
								<HoverButton
									key="custom"
									config={config}
									icon={faPaintBrush}
									onClick={() =>
										togglePicker(mesh.meshName, mesh.materials[0]?.color)
									}
									style={{
										...buttonStyle,
                                        color: isColorCloseToWhite(picker?.color || "#ffffff")
												? "gray"
												: "white",
										border: picker?.color ? "2px dashed white" : "0px",
									}}
									BackgroundColor={picker?.color || "conic-gradient(from 180deg, #ff9a9e, #fad0c4, #fbc2eb, #a1c4fd, #c2e9fb, #d4fc79, #96e6a1, #ff9a9e)"}
								/>
							</div>
							{/* Picker only visible if open for this mesh */}
							{picker?.show && (
								<div style={{ marginTop: "10px" }}>
									<HexColorPicker
										color={picker.color}
										onChange={(c) => handleCustomChange(mesh.meshName, c)}
									/>
									<HexColorInput
										color={picker.color}
										onChange={(c) => handleCustomChange(mesh.meshName, c)}
										style={{
											marginTop: "5px",
											width: "100px",
											padding: "5px",
											borderRadius: "5px",
											border: "1px solid #ccc",
										}}
									/>
								</div>
							)}
						</Stack>
					);
				})}
			</div>
		</motion.div>
	);
}
function LayerTab({ model, toggleGeometryVisibility }) {
	const ref = useRef();
	const [visibilityMap, setVisibilityMap] = useState({});
	const [collapsed, setCollapsed] = useState({});
	const [loadedChildren, setLoadedChildren] = useState({}); // Track loaded children

	// Initialize visibility map and collapse state once when the model is loaded
	useEffect(() => {
		if (model) {
			const initializeVisibility = (object) => {
				const visMap = {};
				const collapseMap = {};
				object.traverse((child) => {
					visMap[child.uuid] = child.visible;
					collapseMap[child.uuid] = true; // Initially, everything is collapsed
				});
				return { visMap, collapseMap };
			};
			const { visMap, collapseMap } = initializeVisibility(model);
			setVisibilityMap(visMap);
			setCollapsed(collapseMap);
		}
	}, [model]);

	// Handle visibility toggle with minimal state updates
	const handleToggleVisibility = (uuid) => {
		toggleGeometryVisibility(uuid, model);

		setVisibilityMap((prev) => ({
			...prev,
			[uuid]: !prev[uuid],
		}));
	};

	// Load children asynchronously only when the parent is expanded
	const handleToggleCollapse = async (uuid, child) => {
		setCollapsed((prev) => ({
			...prev,
			[uuid]: !prev[uuid], // Toggle the collapsed state
		}));

		// Load children lazily when expanded
		if (!loadedChildren[uuid] && child.children && child.children.length > 0) {
			await new Promise((resolve) => {
				setTimeout(() => {
					setLoadedChildren((prev) => ({
						...prev,
						[uuid]: child.children, // Load children
					}));
					resolve();
				}, 500); // Simulate a delay
			});
		}
	};

	// Render children only when a parent is expanded
	const renderChildren = useMemo(() => {
		const recursiveRender = (children, level = 0) => {
			if (!children || level > 10) return null;

			return children.map((child, index) => {
				const isParent = child.children && child.children.length > 0;
				const isCollapsed = collapsed[child.uuid];
				const hasLoadedChildren = loadedChildren[child.uuid];

				return (
					<div key={child.uuid} style={{ paddingLeft: `20px` }}>
						{/* Align items to the left */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							{/* Collapse/Expand Button */}
							{isParent && (
								<HoverButton
									key={index}
									config={config}
									icon={isCollapsed ? faChevronRight : faChevronDown}
									onClick={() => handleToggleCollapse(child.uuid, child)} // Load children when expanding
									style={{
										...buttonStyle,
										fontSize: "15px",
										margin: "5px",
									}}
									BackgroundColor={isCollapsed ? "transparent" : "slate"}
								/>
							)}

							{/* Visibility Button */}
							<HoverButton
								key={index}
								config={config}
								icon={faEye}
								onClick={() => handleToggleVisibility(child.uuid)}
								style={{
									...buttonStyle,
									fontSize: "15px",
									margin: "5px",
								}}
								BackgroundColor={visibilityMap[child.uuid] ? "green" : "red"}
							/>

							{/* Item Name */}
							<strong style={{ color: "white", flexGrow: 1 }}>
								{child.name || `Unnamed (${child.type})`}
							</strong>
						</div>

						{/* Render children only when expanded and if they exist */}
						{!isCollapsed &&
							hasLoadedChildren &&
							recursiveRender(loadedChildren[child.uuid], level + 1)}
					</div>
				);
			});
		};

		// Memoize the entire structure for performance gains
		return recursiveRender(model?.children);
	}, [model, visibilityMap, collapsed, loadedChildren]); // Recalculate only when dependencies change

	if (!model) {
		return null; // Return null if model is not available
	}

	return (
		<motion.div
			ref={ref}
			initial={{ opacity: 0, x: -50 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -50 }}
			transition={{ duration: 0.5 }}
			style={{
				...containerStyle,
				top: "0",
				left: "0",
				maxHeight: "60%",
				overflowY: "auto", // Add vertical scroll bar
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					top: "0",
					bottom: "0",
				}}
			>
				<strong style={{ color: "white", padding: "0px 20px" }}>Layers:</strong>
				{renderChildren}
			</div>
		</motion.div>
	);
}

const isColorCloseToWhite = (color) => {
	// Convert the color to RGB
	const rgb = parseInt(color.slice(1), 16); // Assuming color is in hex format
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = (rgb >> 0) & 0xff;

	// Calculate the brightness of the color
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;

	// Return true if the brightness is close to white
	return brightness > 200; // Adjust the threshold as needed
};
function createUI(modelOptions, updateMaterial) {
	const container = document.createElement("div");
	container.id = "material-selector-container";
	container.style.display = "flex";
	container.style.flexDirection = "column";
	container.style.alignItems = "flex-start";
	document.body.appendChild(container);
	const materials = modelOptions.materials;
	materials.forEach((mesh) => {
		const meshRow = document.createElement("div");
		meshRow.style.margin = "10px";
		meshRow.className = "material-select-row";

		const meshLabel = document.createElement("span");
		meshLabel.textContent = mesh.displayName + ": ";
		meshLabel.style.marginRight = "10px";
		meshRow.appendChild(meshLabel);

		const circleContainer = document.createElement("div");
		circleContainer.className = "circle-container";
		meshRow.appendChild(circleContainer);

		mesh.materials.forEach((material) => {
			const colorCircle = document.createElement("div");
			colorCircle.className = "material-color-circle";
			colorCircle.style.width = "20px";
			colorCircle.style.height = "20px";
			colorCircle.style.borderRadius = "50%";
			colorCircle.style.backgroundColor = material.color;
			colorCircle.style.display = "inline-block";
			colorCircle.style.marginRight = "5px";
			colorCircle.style.cursor = "pointer";

			colorCircle.onclick = () => updateMaterial(mesh.meshName, material.name);
			circleContainer.appendChild(colorCircle);
		});

		//   container.appendChild(meshRow);
	});
}
function TweenUpdater() {
	useFrame(() => {
		TWEEN.update();
	});
	return null;
}

function Model({ url, preLoad, onLoaded, clipPlanes = [] }) {
	const [options, setOptions] = useState(null);
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
	const gltf = useLoader(GLTFLoader, url, (loader) => {
		loader.setDRACOLoader(dracoLoader);
	});
	useEffect(() => {
		// Fetch the options asynchronously and update the state
		preLoad(url).then((fetchedOptions) => {
			setOptions(fetchedOptions || {}); // Default to an empty object if no options
		});
	}, [url, preLoad]);

	useEffect(() => {
		if (!options) return;
		function traverseScene(node, parent, depth = 0, options) {
			const nodesToRemove = [];
			if (
				(node.isMesh &&
					(depth > options.maxDepth ||
						(options.excludeNames &&
							options.excludeNames.includes(node.name)))) ||
				(options.excludeContaining &&
					options.excludeContaining.some((exclude) =>
						node.name.includes(exclude)
					))
			) {
				if (options.includeNames && options.includeNames.includes(node.name)) {
				} else {
					nodesToRemove.push(node);
				}
			}

			// Recursively visit children
			if (node.children) {
				node.children.forEach((child) => {
					traverseScene(child, node, depth + 1, options);
				});
			}

			// Remove marked nodes from parent's children array
			if (parent && nodesToRemove.length > 0) {
				const newChildren = parent.children.filter(
					(child) => !nodesToRemove.some((node) => node === child)
				);
				parent.children = newChildren;
			}
		}
		traverseScene(gltf.scene, null, 0, options);
		onLoaded(gltf.scene);
		gltf.scene.traverse((child) => {
			if (child.isMesh) {
				child.material.clippingPlanes = clipPlanes;
				child.material.clipIntersection = false; // Optionally set clipIntersection to true for intersected clipping
				child.material.needsUpdate = true; // Ensure material gets updated
			}
		});
	}, [gltf.scene, onLoaded, options]);

	return <primitive object={gltf.scene} />;
}
// Function to calculate the bounding box for all mesh geometries in the object (even if nested)
function calculateObjectBounds(object) {
	const box = new Box3();

	// Traverse the entire object and find all Mesh instances (including nested ones)
	object.traverse((node) => {
		if (node instanceof Mesh) {
			box.expandByObject(node); // Expand the bounding box to include this mesh
		}
	});

	// If no mesh was found and the bounding box is empty, return a default bounding box (1m x 1m x 1m)
	if (box.isEmpty()) {
		console.warn(
			"No mesh geometry found in the object. Using default bounding box."
		);
		return new Box3(new Vector3(-0.5, -0.5, -0.5), new Vector3(0.5, 0.5, 0.5)); // 1m by 1m by 1m
	}

	return box; // Return the calculated bounding box
}
function toggleGeometryVisibility(uuid, model) {
	if (!model) {
		console.error("Model is not defined");
		return;
	}

	// Use getObjectByProperty instead of getObjectByName to search by uuid
	const object = model.getObjectByProperty("uuid", uuid);
	if (object) {
		object.visible = !object.visible; // Toggle visibility
	} else {
		console.warn(`Object with UUID ${uuid} not found`);
	}
}

function Viewer({
	modelUrl,
	style,
	admin = false,
	modelOptionsFileName = "modelOptions",
}) {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [showFrostedLayer, setShowFrostedLayer] = useState(false);
	const [showMaterialOptions, setShowMaterialOptions] = useState(false);
	const [showLayerOptions, setShowLayerOptions] = useState(false);
	const [showClippingOptions, setShowClippingOptions] = useState(false);
	const [selectedScene, setSelectedScene] = useState(null);
	const [modelOptions, setModelOptions] = useState(null);
	const [modelLoadOptions, setModelLoadOptions] = useState(null);
	const [uiCreated, setUICreated] = useState(false); // Track if UI has been created
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(true); // Loading state
	const [activeCameraIndex, setActiveCameraIndex] = useState(0);
	const cameraRef = useRef();
	const containerRef = useRef();
	const [cameras, setCameras] = useState([]);
	const controlsRef = useRef(); // Ref to store the actual OrbitControls instance
	const isMobile = useResponsive();
	const [clipValues, setClipValues] = useState({ x: 0, y: 0, z: 0 });
	const [clipVector, setClipVector] = useState({ x: 1, y: -1, z: -1 });
	const [enabledAxes, setEnabledAxes] = useState({
		x: false,
		y: false,
		z: false,
	});
	const [objectBounds, setObjectBounds] = useState(null); // Object bounds state
	const [isVR, setIsVR] = useState(false); // Track dragging state
	const clipPlanes = [
		enabledAxes.x
			? new Plane(new Vector3(clipVector.x, 0, 0), clipValues.x)
			: null,
		enabledAxes.y
			? new Plane(new Vector3(0, 0, clipVector.z), clipValues.y)
			: null,
		enabledAxes.z
			? new Plane(new Vector3(0, clipVector.y, 0), clipValues.z)
			: null,
	].filter(Boolean); // Filter out null planes
	const [originalDimensions, setOriginalDimensions] = useState({
		width: "100%",
		height: "100%",
	});
	const raycasterRef = useRef(new Raycaster()); // Add Raycaster here
	const togglePlane = (axis) => {
		if (axis == "none") {
			setEnabledAxes((prev) => ({
				x: false,
				y: false,
				z: false,
			}));
		} else {
			setEnabledAxes((prev) => ({ ...prev, [axis]: !prev[axis] }));
		}
	};
	const applyClip = (axis, value) => {
		setClipValues((prev) => ({ ...prev, [axis]: value }));
	};
	const reverseClip = (axis) => {
		if (axis) {
			setClipVector((prev) => ({
				x: axis == "x" ? -prev.x : prev.x,
				y: axis == "y" ? -prev.y : prev.y,
				z: axis == "z" ? -prev.z : prev.z,
			}));
		} else {
			setClipVector((prev) => ({
				x: -prev.x,
				y: -prev.y,
				z: -prev.z,
			}));
		}
	};
	const toggleFullScreen = () => {
		if (!isFullScreen) {
			// Store the original dimensions before entering full screen
			setOriginalDimensions({
				width: containerRef.current.clientWidth,
				height: containerRef.current.clientHeight,
			});
		}
		setIsFullScreen(!isFullScreen);
	};
	const toggleMaterialOptions = () =>
		setShowMaterialOptions(!showMaterialOptions);
	const toggleClippingOptions = () =>
		setShowClippingOptions(!showClippingOptions);
	const toggleLayerOptions = () => setShowLayerOptions(!showLayerOptions);

	const introAnimation = () => {
		if (cameraRef.current) {
			cameraRef.current.enabled = false; // Disable controls during animation
		}
		cameraRef.current.updateProjectionMatrix();
		if (cameras[0].start_position != null) {
			cameraRef.current.position.set(
				cameras[0].start_position.x,
				cameras[0].start_position.y,
				cameras[0].start_position.z
			);
		} else {
			cameraRef.current.position.set(0, 40, 4);
		}
		cameraRef.current.lookAt(
			cameras[0].target.x,
			cameras[0].target.y,
			cameras[0].target.z
		);
		cameraRef.current.fov = 50;

		const cameraPosition = cameraRef.current.position;

		new TWEEN.Tween(cameraPosition)
			.to(
				{
					x: cameras[0].position.x,
					y: cameras[0].position.y,
					z: cameras[0].position.z,
				},
				6500
			)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => {
				cameraRef.current.position.set(
					cameraPosition.x,
					cameraPosition.y,
					cameraPosition.z
				);
				cameraRef.current.lookAt(0, 1, 0);
			})
			.onComplete(() => {
				console.log("Animation completed");
				if (cameraRef.current) {
					cameraRef.current.enabled = true; // Enable orbit controls after animation
					switchCamera(true);
				}
			})
			.start();
	};
	const switchCamera = (first = false) => {
		// fetchModelOptions(modelUrl);
		console.log("Actual camera", activeCameraIndex);
		if (cameras != null && cameras != undefined) {
			console.log("Switching camera");
			let index = activeCameraIndex;
			console.log("available cameras: ", cameras);
			if (index < cameras.length) {
				index++;
			}
			if (index >= cameras.length) {
				index = 0;
			}
			if (first == true) {
				index = 0;
			}
			console.log("Switching to camera index:", index);
			setActiveCameraIndex(index);
			applyCameraSettings(cameraRef.current, cameras[index]);
		}
	};
	function ControllerHandler(first = false) {
		const { player, isPresenting } = useXR();
		useXREvent("select", (event) => {
			// fetchModelOptions(modelUrl);
			if (cameras != null && cameras != undefined) {
				console.log("Switching camera");
				let index = activeCameraIndex;
				console.log("available cameras: ", cameras);
				if (index < cameras.length) {
					index++;
				}
				if (index >= cameras.length) {
					index = 0;
				}
				if (first == true) {
					index = 0;
				}
				console.log("Switching to camera index:", index);
				setActiveCameraIndex(index);
				applyCameraSettings(cameraRef.current, cameras[index]);
				player.position.set(
					cameras[index].position.x,
					cameras[index].position.y - 1.6,
					cameras[index].position.z
				); // Example: Move player to a new position
				player.rotation.set(0, -Math.PI / 2, 0);
			}
		});

		return null;
	}
	const fetchModelOptions = (modelUrl) => {
		const baseUrl = modelUrl.substring(0, modelUrl.lastIndexOf("/"));
		const jsonUrl = modelOptionsFileName
			? `${baseUrl}/${modelOptionsFileName}.json`
			: `${baseUrl}/modelOptions.json`;

		fetch(jsonUrl)
			.then((response) => response.json())
			.then((data) => {
				setModelOptions(data);
				// console.log("MODEL33", selectedScene);
				// console.log("Cameras:", data.cameras);
				setCameras(data.cameras);
				if (data.cameras != null) {
					applyCameraSettings(data.cameras[activeCameraIndex]);
				}
				// console.log("Model options:", data);
				setLoaded(true);
				setLoading(false); // Hide loading indicator
			})
			.catch((error) =>
				console.error("Error loading material options:", error)
			);
	};
	const fetchModelLoadOptions = (modelUrl) => {
		const baseUrl = modelUrl.substring(0, modelUrl.lastIndexOf("/"));
		const jsonUrl = `${baseUrl}/${modelOptionsFileName || "modelOptions"}.json`;

		return fetch(jsonUrl)
			.then((response) => response.json())
			.then((data) => {
				if (data?.options) {
					console.log("Data options:", data.options);
					return data.options;
				}
				console.warn("No options found in the response");
				return {};
			})
			.catch((error) => {
				console.error("Error loading model load options:", error);
				return {};
			});
	};

	const setOrbitControlsLimits = (controls, cameraSettings) => {
		if (controls) {
			controls.enableDamping = true;
			controls.dampingFactor = 0.04;

			// Set target for OrbitControls
			controls.target.set(
				cameraSettings.target.x,
				cameraSettings.target.y,
				cameraSettings.target.z
			); // Example target

			if (cameraSettings.type === "orbit") {
				controls.minDistance = 5;
				controls.maxDistance = 60;
				controls.enablePan = true;
				controls.maxPolarAngle = Math.PI / 2 - 0.005;
			} else if (cameraSettings.type === "stationary") {
				controls.minDistance = 0.1;
				controls.maxDistance = 1;
				controls.enablePan = false;
				controls.maxPolarAngle = 2 * Math.PI;
			}
			if (admin) {
				controls.enablePan = true;
			}
			controls.update(); // Always update the controls after changes
		}
	};
	const applyCameraSettings = (camera, cameraSettings) => {
		if (camera && cameraSettings) {
			camera.position.set(
				cameraSettings.position.x,
				cameraSettings.position.y,
				cameraSettings.position.z
			);

			const controls = controlsRef.current; // Use the ref to get OrbitControls instance
			if (controls) {
				controls.target.set(
					cameraSettings.target.x,
					cameraSettings.target.y,
					cameraSettings.target.z
				);
				setOrbitControlsLimits(controls, cameraSettings);
				controls.update();
			}

			camera.lookAt(
				cameraSettings.target.x,
				cameraSettings.target.y,
				cameraSettings.target.z
			);

			camera.updateMatrixWorld();
			camera.updateProjectionMatrix();
		}
	};
	useEffect(() => {
		if (loaded && cameraRef.current) {
			introAnimation();
		}
	}, [loaded]);
	useEffect(() => {
		if (modelOptions && !uiCreated) {
			createUI(modelOptions, updateMaterial); // Create UI once
			setUICreated(true); // Mark UI as created to prevent future calls
		}
	}, [modelOptions]);

	const handleModelLoaded = (scene) => {
		if (!loaded) {
			setSelectedScene(scene);
			fetchModelOptions(modelUrl); // Fetch the material options once the model is loaded
			const bounds = calculateObjectBounds(scene); // Get bounds of the object
			setObjectBounds(bounds);
			// console.log("Cameras:", cameras);
		}
	};

	const updateMaterial1 = (meshName, materialName) => {
		if (selectedScene && modelOptions) {
			const selectedMesh = selectedScene.getObjectByName(meshName);
			const materialData = modelOptions.materials
				.find((mesh) => mesh.meshName === meshName)
				.materials.find((mat) => mat.name === materialName);

			if (selectedMesh) {
				selectedMesh.material.color.set(materialData.color);
				selectedMesh.material.roughness = materialData.roughness;
				selectedMesh.material.metalness = materialData.metalness;
				selectedMesh.material.needsUpdate = true;
			}
		}
	};
	const updateMaterial2 = (
		meshName,
		materialName,
		overrideColor = null,
		materialBased = false
	) => {
		if (!selectedScene || !modelOptions) return;

		// Find the selected entry from modelOptions
		const entry = modelOptions.materials.find(
			(m) =>
				(Array.isArray(m.meshName) && m.meshName.includes(meshName)) ||
				(typeof m.meshName === "string" && m.meshName === meshName) ||
				m.materialName === meshName
		);

		if (!entry) return;

		const materialData = entry.materials.find(
			(mat) => mat.name === materialName
		);
		if (!materialData) return;

		let targets = [];

		if (entry.materialName) {
			// MODE 3: Find all meshes using this material by name
			selectedScene.traverse((child) => {
				if (child.isMesh && child.material?.name === entry.materialName) {
					targets.push(child);
				}
			});
		} else if (Array.isArray(entry.meshName)) {
			// MODE 2: Array of mesh names
			targets = entry.meshName
				.map((name) => selectedScene.getObjectByName(name))
				.filter(Boolean);
		} else if (entry.meshName) {
			// MODE 1: Single mesh name
			const mesh = selectedScene.getObjectByName(entry.meshName);
			if (mesh) targets.push(mesh);
		}

		targets.forEach((mesh) => {
			if (mesh.material) {
				mesh.material.color.set(overrideColor || materialData.color);
				if (!overrideColor) {
					mesh.material.roughness = materialData.roughness;
					mesh.material.metalness = materialData.metalness;
				}
				mesh.material.needsUpdate = true;
			}
		});
	};
    const updateMaterial3 = (meshName, materialName = null, custom = null) => {
        if (!selectedScene || !modelOptions) return;

        if (custom) {
            // Custom direct color change
            selectedScene.traverse((child) => {
                if (child.isMesh && child.name === meshName) {
                    child.material.color.set(custom.color);
                    child.material.needsUpdate = true;
                }
            });
            return;
        }

        const selectedMesh = selectedScene.getObjectByName(meshName);
        const matData = modelOptions.materials
            .find((m) => m.meshName === meshName)
            .materials.find((mat) => mat.name === materialName);

        if (selectedMesh && matData) {
            selectedMesh.material.color.set(matData.color);
            selectedMesh.material.roughness = matData.roughness;
            selectedMesh.material.metalness = matData.metalness;
            selectedMesh.material.needsUpdate = true;
        }
    };
    const updateMaterial = (meshName, materialName = null, custom = null) => {
        if (!selectedScene || !modelOptions) return;

        if (custom) {
            selectedScene.traverse((child) => {
                if (child.isMesh && child.name === meshName) {
                    child.material.color.set(custom.color);
                    child.material.needsUpdate = true;
                }
            });
            return;
        }

        const selectedMesh = selectedScene.getObjectByName(meshName);
        const matData = modelOptions.materials
            .find((m) => m.meshName === meshName)
            .materials.find((mat) => mat.name === materialName);

        if (selectedMesh && matData) {
            selectedMesh.material.color.set(matData.color);
            selectedMesh.material.roughness = matData.roughness;
            selectedMesh.material.metalness = matData.metalness;
            selectedMesh.material.needsUpdate = true;
        }
    };

	return (
		<div style={{ padding: "10px", ...style }}>
			<AnimatePresence>
				<motion.div
					ref={containerRef}
					className={`Viewer ${isFullScreen ? "fullscreen" : ""}`}
					style={{
						overflow: "hidden",
						width: "100%",
						height: "100%",
						position: isFullScreen ? "fixed" : "relative",
						zIndex: isFullScreen ? 1000 : "auto",
						background: "linear-gradient(135deg, #1f1f2e 0%, #141629 100%)",
						borderRadius: "30px",
						boxShadow: ` -2px -2px 5px rgba(200, 200, 200,0.2), 3px 3px 5px rgba(77, 77, 77, 0.6)`,
					}}
					animate={{
						borderRadius: isFullScreen ? "0px" : "30px",
						width: isFullScreen ? "100vw" : originalDimensions.width,
						height: isFullScreen ? "100vh" : originalDimensions.height,
						maxWidth: isFullScreen ? "100vw" : "100%",
						top: isFullScreen ? 0 : "",
						bottom: isFullScreen ? 0 : "",
						right: isFullScreen ? 0 : "",
						left: isFullScreen ? 0 : "",
					}}
					transition={{ duration: 0.2 }}
					onAnimationStart={() => {
						setShowFrostedLayer(true);
					}}
				>
					{
						<motion.div
							initial={{ opacity: 0 }}
							animate={{
								opacity: showFrostedLayer ? [1, 1, 0] : 0,
								zIndex: showFrostedLayer ? 1 : 0,
							}}
							transition={{ duration: 1, times: [0.05, 0.8, 0.95] }}
							style={{
								position: "absolute",
								opacity: 1,
								width: "100%",
								height: "100%",
								backdropFilter: "blur(1000px)",
								backgroundColor: "rgba(71, 142, 196, 0.1)",
								zIndex: 1,
							}}
							onAnimationComplete={() => setShowFrostedLayer(false)}
						/>
					}
					{/* Material Options displayed within Canvas when toggled */}
					{/* Show loading indicator while loading */}
					{loading && <LoadingIndicator />}
					<VRButton
						className="vr-button"
						onClick={() => setIsVR(useXR.isPresenting)}
					/>
					<Canvas
						onCreated={({ camera, gl }) => {
							cameraRef.current = camera;
							// Ensuring Canvas resizes with container changes
							gl.localClippingEnabled = true;
							gl.setSize(
								containerRef.current.clientWidth,
								containerRef.current.clientHeight
							);
							gl.xr.enabled = true;
						}}
					>
						<XR>
							<Environment preset="sunset" />
							{!isVR && (
								<OrbitControls
									ref={controlsRef}
									enableZoom={isFullScreen || !isMobile}
								/>
							)}
							{/* VR specific components */}
							<ControllerHandler switchCameraa={switchCamera} />
							<Controllers rayMaterial={{ color: "blue" }} />
							<Hands />
							<ambientLight intensity={0.5} />
							<pointLight position={[10, 10, 10]} />
							<Model
								preLoad={fetchModelLoadOptions}
								url={modelUrl}
								onLoaded={handleModelLoaded}
								clipPlanes={clipPlanes}
							/>
							<TweenUpdater />
						</XR>
					</Canvas>
					<AnimatePresence>
						{!isFullScreen && (
							<FullScreenButton toggleFullScreen={toggleFullScreen} />
						)}
					</AnimatePresence>
					{showMaterialOptions && (isFullScreen || !isMobile) && (
						<ColorsTab
							modelOptions={modelOptions}
							updateMaterial={updateMaterial}
						/>
					)}
					{admin && showClippingOptions && (isFullScreen || !isMobile) && (
						<ClippingTab
							applyClip={applyClip}
							reverseClip={reverseClip}
							clipValues={clipValues}
							setClipValues={setClipValues}
							togglePlane={togglePlane}
							enabledAxes={enabledAxes}
							objectBounds={objectBounds}
						/>
					)}
					{admin && false && (
						<DecalTool
							raycaster={raycasterRef.current} // Pass raycaster here
							model={selectedScene}
							camera={cameraRef.current} // Pass camera reference here
						/>
					)}
					{admin && showLayerOptions && (
						<LayerTab
							model={selectedScene}
							updateMaterial={updateMaterial}
							toggleGeometryVisibility={toggleGeometryVisibility}
						/>
					)}
					{/* FullScreen Button */}
					{(isFullScreen || !isMobile) && (
						<OptionsButton
							toggleFullScreen={toggleFullScreen}
							toggleMaterialOptions={toggleMaterialOptions}
							toggleLayerOptions={toggleLayerOptions}
							toggleClippingOptions={toggleClippingOptions}
							switchCamera={switchCamera}
							cameras={cameras}
							admin={admin}
							showResize={isFullScreen}
							hasColorOptions={true}
							hasLayers={true}
						/>
					)}
				</motion.div>
			</AnimatePresence>
		</div>
	);
}

export default Viewer;
